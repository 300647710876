
















































































import { ApiResource } from '@/types';
import Vue from 'vue';

export default Vue.extend({
  props: {
    commission: {
      type: Object as () => ApiResource.Commission,
      required: true,
    },
  },
});
